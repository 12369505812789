import React from 'react';
import LeftBar from './leftBar';
import BannerWrapper, {
  ContentWrapper,
  TextArea,
  ImageArea,
} from './bannerSection.style';

import BannerImage from './banner-image.jpeg';

const BannerSection = () => {
  return (
    <BannerWrapper>
      <LeftBar text="SCROLL DOWN" offset={81} sectionId="#feature" />
      <ContentWrapper>
        <TextArea>
          <h2>
            <small>Why do we believe the Greenway plans are in direct conflict with Flood Management & Risk Reduction Policy?</small>
          </h2>
          <p>
            In response to a discussion with <strong>Councillor Steve Williams</strong> of <strong>Waverley Borough council</strong>. We have set out
            the following deep dive into to why we believe the current Greenway proposals (to build infrastructure across the Lammas Lands) are <strong>in direct conflict with the Environmental Agency guidance towards managing and mitigating Flood Risk.</strong>.
          </p>
        </TextArea>
        <ImageArea style={{ backgroundImage: `url(${BannerImage})` }} />
      </ContentWrapper>
    </BannerWrapper>
  );
};

export default BannerSection;
