import React from 'react';
import Container from 'common/components/UI/Container';
import { Link } from 'gatsby';
import SectionWrapper from './workSection.style';
import Image from "../../../../common/components/Image";
import library from './library.jpeg';
import Plan from './plan.jpeg';
import Bridge from './bridge.png';

const WorkSection = () => {
  return (
    <SectionWrapper id="work">
      <Container width="1260px">
        <p>
          <strong>Note: If you are coming to this page fresh, then we recommend reading our <Link to="/godalming-greenway">wider issues with the Lammas Lands</Link> segments of the Greenway plans before reading this as this goes quite deep into published policy.</strong></p>
          <p>
            This article puts the proposed plans for the Lammas Lands segments of the Godalming Greenway proposal through the <a href="https://www.gov.uk/guidance/flood-risk-and-coastal-change#flood-zone-and-flood-risk-tables" target="_blank">Environment Agency Guidelines</a> and <a href="/wlp-catchment-plan_sert_-draft-v3.pdf" target="_blank">Waverly Borough Council Level 1 strategic flood assessment</a> (in itself derived from Environment Agency guidelines).
          </p>
        <p>
          We do not believe under these guidelines that the current project proposal (put to the public in consultation) meets the criteria over potentially safer options in lower flood risk areas.
        </p>
        <h2>Applicable Regulations, Plans & Frameworks</h2>
        <p>The following regulations, plans & frameworks define or play a part in the enforcement of flood risk management and mitigation,</p>
        <p>
          <strong>The Flood and Water Management Act (2010)</strong> provides for a more comprehensive management of flood risk, designating roles and responsibilities for different Risk Management Authorities. Designates Surrey County Council as the LLFA, with duties and responsibilities for managing local flood risk (defined as flooding from surface water, groundwater and ordinary watercourses).
        </p>
        <p>
          <strong>The Flood Risk Regulations (2009)</strong> transpose the EU Floods Directive into law in England. It aims to provide a consistent approach to flood risk across Europe.
        </p>
        <p>
          <strong>The Revised National Planning Policy Framework</strong> was published by the UK's DCLG in March 2012, and updated in July 2018, consolidating over two dozen previously issued documents called Planning Policy Statements (PPS) and Planning Policy Guidance Notes (PPG) for use in England.
        </p>
        <p>
          <strong>The Thames Catchment Flood Management Plan</strong> (River Wey catchment) establishes flood risk management policies which will deliver sustainable flood risk management for the long term.
        </p>
        <h2>Council & Agency Roles</h2>
        <p>The folowing parties play key roles in both defining and enforcing flood risk management and mitigation,</p>
        <p>
          <strong>Waverley Borough Council</strong> as a Local Planning Authority (LPA) has a responsibility to consider flood risk in their strategic land use planning and the development of the Local Plan. The NPPF requires LPAs to undertake a Strategic Flood Risk Assessment (SFRA) and to use their findings, and those of other studies, to inform strategic land use planning including the application of the Sequential Test, which seeks to steer development towards areas of lowest flood risk prior to consideration of areas of greater risk. Waverley Borough Council is also required to consider flood risk and, in the case of the Lammas Lands segments, apply the Sequential and Exception Tests when assessing development.
        </p>
        <p>
          <strong>The Environment Agency</strong> is responsible for managing the risk of flooding from Main Rivers and has a responsibility to provide a strategic overview for all flooding sources.
        </p>
        <p>
          <strong>Surrey County Council</strong> As the Lead Local Flood Authority (LLFA), under the Flood and Water Management Act (FWMA) has a duty to take the lead in the coordination of local flood risk management, specifically defined as flooding from surface water, groundwater and ordinary watercourses.
        </p>
        <h2>The Detail</h2>
        <p>The Environment Agency and it’s sequential approach is a pivotal decision-making tool designed to ensure that <strong>sites at little or no risk of flooding are developed in preference to sites at higher risk</strong>. This helps avoid the development of sites that are inappropriate on flood risk grounds. The subsequent application of the Exception Test ensures that developments in flood risk areas will only occur where flood risk is clearly outweighed by other sustainability drivers.</p>
        <p>The NPPF seeks to assess the probability of flooding from rivers by categorising areas within the fluvial floodplain into zones of low, medium and high probability. <strong>The Lammas Lands being categorised as a Flood Zone 3b</strong>, meaning it is at the highest possible risk of flooding and is a functional floodplain. This is Land where water has to flow or be stored in times of flood, or land purposely designed to be flooded in an extreme flood event (flood storage area).</p>
        <p><strong>Only where there are no reasonably available sites in Flood Zones 1 or 2 should the suitability of sites in Flood Zone 3 or above (areas with a high probability of river flooding) be considered</strong>, taking into account the flood risk vulnerability of land uses and applying the Exception Test. Only where alternative sites in areas at lower risk of flooding are not available, it may be acceptable develop in areas at risk of flooding. </p>
        <p>The following types of projects are allowed on a floodplain and could be argued as part of the Lammas Lands proposals,</p>
        <ul>
          <li>Essential infrastructure</li>
          <li>Water Compatible</li>
        </ul>
        <p>For the sake of any argument we will say that the Lammas Land segments of the Greenway project align into water compatible. We are immediately discounting essential infrastructure as the public definitely do not need to cross these areas at personal risk, they are not a major ingress or egress route through the town, or a mass evacuation route. As water compatible infrastructure, it could fall into one segment of allowed water compatible infrastructure:</p>
        <ul>
          <li>Amenity open space, nature conservation and biodiversity, outdoor sports and recreation and essential facilities such as changing rooms.</li>
        </ul>
        <p>In Flood Zone 3b (functional floodplain) water-compatible infrastructure that has passed the Exception Test, should be designed and constructed to:</p>
        <ul>
          <li>remain operational and safe for users in times of flood;</li>
          <li>result in no net loss of floodplain storage;</li>
          <li>not impede water flows and not increase flood risk elsewhere.</li>
        </ul>
        <p>We do not believe that the boardwalk or path would be able to remain operational (or safe) during times of flood, as you can see from the below picture taken from the library in 2020, the landing site of the proposed bridge is under water during flood conditions, which would render the proposed route out of operation, a danger to the public and in breach of the above conditions of development. Factoring in climate change, this is only going to get worse.</p>
        <Image style={{ width: '100%', maxWidth: '800px', margin: '0 auto', padding: '1em 0' }} src={library} alt="library flooding"/>
        <p>Furthermore, the requirement for no loss of floodplain storage means that it is not possible to modify ground levels on the Lammas Lands, as there is no land readily available for lowering to bring it into the floodplain. The project would have a non zero effect on storage and at minimum, this would need to be offset elsewhere.</p>
        <p><strong>Councillor Steve Williams</strong> had the following to say regarding flood flows.</p>
        <p style={{ fontStyle: 'italic' }}>“The far shorter Boardwalk proposal off Bridge Road is across the floodplain, not parallel to a key drainage channel. As such it runs with, not across, the drainage direction. It is above the 100 year flood contour and unobstructed. It minimises soil compaction to a miniscule proportion of the floodplain.”</p>
        <p>Both the lead architect and Steve make use of the words minuscule and marginal in describing storage loss, both currently from an unsubstantiated viewpoint given no feasibility test has taken place, neither of these are no net loss and we would like to know how infrastructure measured in tons will be offset on a functional floodplain…..</p>
        <p>On a side note we have been informed by an external party, that the 100 year flood contour was breached in 2013/14, however it should be noted that extra flood protections have been put in place since that occurrence.</p>
        <p>From our own experiences, we find particular problem with the use of words like marginal and miniscule.
          These words are highly subjective, with marginality being extremely open ended especially with the net result of flooding having impact on peoples homes and public safety, not just land.</p>
        <p>It should also be noted, <Link to="/our-response/management-plan/">as mentioned in our article regarding the issues this generates regarding the Management Plan</Link> and and highlighted as the primary objective of the plan itself, cutting off the only vehicle access into Overgone meadow will have serious ramifications on the flood capability of the site over time.</p>
        <h2>Application of the Sequential Test</h2>
        <p>The sequential test poses the following questions,</p>
        <ul>
          <li>What other locations with a lower risk of flooding have you considered for the proposed development?</li>
          <li>If you have not considered any other locations, what are the reasons for this?</li>
          <li>Explain why you consider the development cannot reasonably be located within an area with the lowest probability of flooding (flood zone 1); and, if your chosen site is within flood zone 3, explain why you consider the development cannot reasonably be located in flood zone 2.</li>
          <li>As well as flood risk from rivers or the sea, have you taken account of the risk from any other sources of flooding in selecting the location for the development?</li>
        </ul>
        <p>As you can see, all of these points are along the lines of, why are you proposing development on a floodplain rather than anywhere else? With the other options available to the council(s) to provide a safe cycle track, we desperately seek an answer to this.</p>
        <p>The reality is, that safe road cycle paths can most probably be provided away from the floodplain, with the prospect of actually being able to satisfy both commuter and lesiure cyclists, rather than predominantly slower leisure cyclists, as the scheme suggests under Surrey County Councils own admission.</p>
        <p>The following most obvious changes could be made to provide the same net effect,</p>
        <ul>
          <li>
            As recommended by the <a href="/godalming-neighbourhood-plan.pdf" target="_blank">Godalming & Farncombe Neighbourhood
            plan (2017)</a>. Bridge Road road should be re-engineered so as not to only provide pedestrian footpaths that allow
            for pushchairs and wheelchairs to pass at all points without having to encroach on the roadway, but also for a
            safe cycle route along its length.
            <Image style={{ width: '100%', maxWidth: '550px', margin: '0 auto', padding: '1em 0' }} src={Plan} alt="bridge road plan"/>
            As you can see from the above image, this option (2) has existed far longer than the current cycleway plans. with this image coming from around 2016. We are unsure why this was not included in the current consultation.<br /><br />
            We have measured the tightest part of the bridge and it is 10.75 metres from side to side. This is ample space to accommodate 6.9 metres of road traffic, 2 metres of cycle path and 1.85 metres for people to walk side by side. Other than the bridge, there is ample space to allow for 3 metres of cycle track on bridge road.
            <Image style={{ width: '100%', maxWidth: '550px', margin: '0 auto', padding: '2em 0 1em' }} src={Bridge} alt="bridge road cycle path"/>
          </li>
          <li>Catteshall Lane should be made more cyclist friendly, as is actually well placed to service much of the residential properties in the area.</li>
          <li>Borough road should be made more cyclist firendly.</li>
          <li>Meadrow should have segregated cycle lanes along it's length, joining onto the the above Bridge Road and Farncombe cycle paths.</li>
        </ul>
        <p>We have put in a <a href="https://www.whatdotheyknow.com/request/godalming_guildford_greenway#incoming-1904529" target="_blank">freedom of information request</a> to the Council(s) to get hold of the entire options document that was presented to Councillors prior to the consultation, as we are interested in how development on a floodplain was the best option picked and believe it to be in the public interest to release this. The reality being that construction on or reduction of floodplain storage (however marginal) should be the option of absolute last resort when lesser risk options are available, with the <a href="/godalming-neighbourhood-plan.pdf" target="_blank">Godalming & Farncombe Neighbourhood plan (2017)</a> and Bridge Road re-engineering suggesting that not to be the case. We expect this response by the absolute latest on the 23rd of November, under freedom of information regulations.</p>
        <p>We do conclude that maybe a driver behind the Lammas Lands route is that it is seen as the cheaper of the options by Surrey Council as opposed to re-engineering the road (which is ultimately their responsibility), however construction on the Lammas Lands definitely does not seem like the kindest option to the environment, pay respect fo flood risk management or the opinions of the actual users already consulted as part of the <a href="/visitor-survey-2016.pdf" target="_blank">Lammas Land Visitor Survey (2016)</a>.</p>
        <h2>Occupants and users of the development</h2>
        <p>The following questions are posed when considering occupants and users.</p>
        <ul>
          <li>Will the development proposals increase the overall number of occupants and/or people using the building or land, compared with the current use? If this is the case, by approximately how many will the number(s) increase?</li>
          <li>Will the proposals change the nature or times of occupation or use, such that it may affect the degree of flood risk to these people? If this is the case, describe the extent of the change.</li>
        </ul>
        <p>As far as the first point, this one is fairly difficult for us to quantify, however considering the routes are neither used by dog walkers or ramblers during flood conditions, then the answer is from zero to significantly more than zero.</p>
        <p>With regard to the second question it is a little more difficult to answer, but considering the flooding at the landing site for the proposed bridge, yes is our answer.</p>
        <h2>The Exception test</h2>
        <p>The exception test sets out the following questions,</p>
        <ul>
          <li>Would the proposed development provide wider sustainability benefits to the community? If so, could these benefits be considered to outweigh the flood risk to and from the proposed development?</li>
          <li>How can it be demonstrated that the proposed development will remain safe over its lifetime without increasing flood risk elsewhere? </li>
          <li>Will it be possible to for the development to reduce flood risk overall (eg through the provision of improved drainage)?</li>
        </ul>
        <p>This is an area where the council could argue that the sustainability goals of the greenway would cancel out the increased risk. Our argument would be that due to the nature of section of the project itself, it is predominantly a leisure route, with the Surrey County Council formation document saying much the same and also considering the <Link to="/our-response/management-plan/">damage to local ecology</Link> that this project stands to potentially cause. We can therefore assume that the actual sustainability benefits are low. This does not negate the fact that lower risk development options are most probably available.</p>
        <p>With regard to safety over its lifetime, It is important that construction is safe for its users for the development’s lifetime and will not increase flood risk overall. Given the current state of the boardwalk in the Burys, we would say this is a non starter. It is important therefore that the council has a secured and allocated maintenance budget for both the maintenance and future removal of either the path or boardwalk before undertaking them.</p>
        <p>With regard to reducing flood risk, we think this one can be answered with a strong and resounding no.</p>
        <h2>Exemptions</h2>
        <p>Under Environment Agency guidelines, there is <a href="https://www.gov.uk/government/publications/environmental-permitting-regulations-exempt-flood-risk-activities/exempt-flood-risk-activities-environmental-permits#footbridge-over-a-main-river-not-more-than-8m-wide-from-bank-to-bank-fra5" target="_blank">one exemption</a> that could be used in these circumstances;</p>
        <p style={{ fontWeight: 600, paddingLeft: '1.5em' }}>"5. Footbridge over a main river not more than 8m wide from bank to bank (FRA5)"</p>
        <p>This exemption however, falls foul on the following point,</p>
        <p style={{ fontWeight: 600, paddingLeft: '1.5em' }}>"you cannot carry out the works within 100m of any non-agricultural building in the floodplain or another man-made structure on or in the main river"</p>
        <p>This lead us to the <a href="/Standard-Rule-2015-No-28.pdf" target="_blank">following rules document</a> from the environment agency,</p>
        <p style={{ fontWeight: 600, paddingLeft: '1.5em' }}>"Standard rules SR2015 No 28 – Installing a clear span bridge on a main river of up to 8 metres span and 4.2 metres width"</p>
        <p>This document clearly states that work cannot be undertaken to install a bridge that is,</p>
        <ul>
          <li>50m of an area identified as containing a Priority Habitat or Priority Species or Protected Species</li>
          <li>100 metres of any non-agricultural building in the flood plain or another man made structure in, over or under the watercourse</li>
          <li>200 metres of any other bridge over or culvert in the watercourse</li>
        </ul>
        <p>As you can see from the above points, the boardwalk and bridge plan does not stack up. It is only 50 metres from town bridge and roughly 15 metres from the nearest building, which sits on the floodplain. It is also a habitat of principle importance (BAP priority habitats).</p>
        <p>There is also stipulation that such a bridge should not result in environmental harm, which we believe it to do,</p>
        <ul>
          <li>cause harm to the conservation, protection and enhancement of any species and habitats designated under any enactment as having special protection or priority;</li>
        </ul>
        <h2>In Conclusion</h2>
        <p>As stated, development on a floodplain should be the option of absolute last resort, with lesser risk options taking precedence, as enshirned by policy. On the surface, the current proposals appear to be in conflict with risk reduction and risk management policy set out and defined by the Environment Agency and adopted by Waverley Borough Council.</p>
        <p>If a safe cycle route can be provided in areas of less flood risk or even if they are of the same flood risk and have infrastructure upon them that can be accomadated to provide a safe cycle route, then these should be selected primarily rather than further reducing flood storage and capability in the highest risk functional floodplain, whatever the marginality.</p>
        <p>None of this should be new information, as the majority of this text was lifted from Waverley Council policy (as the LPA) and Surrey County Council (as the LLFA).</p>
        <p>As put forward by the <a href="https://www.metoffice.gov.uk/about-us/press-office/news/weather-and-climate/2021/future-extreme-rainfall-more-extreme-than-first-thought" target="_blank">Met Office and off the back of COP26</a>, For the UK, five of the 10 wettest years on record have happened since 2000. increases in hourly rainfall intensity with the latest generation of high-resolution climate models also show more, slower-moving storms, which will lead to high rainfall accumulations. This would suggest current climate modelling needs some serious work. Climate change will make UK flooding more frequent and we need to ‘adapt or die’ according to the Environment Agency.</p>
        <p>We highly recommend that you check out the <Link to="/resources">resources section</Link> of our site, this includes documentation sourced from all authorities and experts involved in the formation of these opinions.</p>
        <h2>Bonus Points: Greenway formation documents</h2>
        <p>The <a href="/GGG-cycle-route.pdf" target="_blank">Surrey County Council Greenway formation documents</a> set out the following points as to what the Greenway should be;</p>
        <ul>
          <li>As a utility route it should be comfortable and well maintained. It should not be prone to flooding or be reduced to mud after rain.</li>
          <li>It is vital that the route is continuous. Stopping and starting is the most difficult aspect of cycling and so a route that allows people on bikes to keep going is important.</li>
          <li>As a key corridor this route will clearly go where people want to go.</li>
        </ul>
        <p>We believe the current plans for the Lammas Lands to fail against all of the above points for the following reasons,</p>
        <ul>
          <li>This route does flood and as shown above, would be rendered unusable in times of flood.</li>
          <li>Cyclists would have to start and stop all the way along the route, especially when entering the Lammas Lands and the Burys</li>
          <li>The route services very few houses when compared with other roads like Catteshall Lane and for shopping the already quiet high street after the roundabout is already the most direct.</li>
          <li>As a road cyclist going from the station, you would use Borough Road and join onto Chalk Road. Hence why we recommend using the paths of desire that have been formed over hundreds of years, making them cycle friendly, over what is being suggested.</li>
        </ul>
      </Container>
    </SectionWrapper>
  );
};

export default WorkSection;
