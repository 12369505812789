import React from 'react';
import { Icon } from 'react-icons-kit';
import { androidArrowForward } from 'react-icons-kit/ionicons/androidArrowForward';
import Container from 'common/components/UI/Container';
import Heading from 'common/components/Heading';
import CallToActionArea, { Left, Right } from './callToAction.style';
const CallToAction = () => {
  return (
    <CallToActionArea>
      <Container className="Container">
        <Left>
          <Heading as="h3" content="Greenway Consultation" />
          <p>
            The consultation has now finished, it is however a good resource for official plans and holds all of the comments for the proposals.
          </p>
        </Left>
        <Right>
          <a href="https://guildfordtogodalminggreenway.commonplace.is/proposals/guildford-to-godalming-greenway-proposals" target="_blank">Open Site <Icon icon={androidArrowForward} /></a>
        </Right>
      </Container>
    </CallToActionArea>
  );
};

export default CallToAction;
