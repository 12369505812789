import React, { Fragment } from 'react';
import Sticky from 'react-stickynode';
import { ThemeProvider } from 'styled-components';
import { charityTheme } from 'common/theme/charity';
import { ResetCSS } from 'common/assets/css/style';
import { DrawerProvider } from 'common/contexts/DrawerContext';
import Navbar from 'containers/Charity/Navbar';
import DrawerSection from 'containers/Charity/DrawerSection';
import BannerSection from 'containers/OurResponse/FloodPolicy/BannerSection';
import Footer from 'containers/Charity/Footer';
import BodySection from 'containers/OurResponse/FloodPolicy/Body';
import CTAOne from 'containers/Greenway/CallToActionOne';
import {
  GlobalStyle,
  CharityWrapper,
  ContentWrapper,
} from 'containers/Charity/charity.style';
import Seo from 'components/seo';


const Index = () => {
  return (
    <ThemeProvider theme={charityTheme}>
      <Fragment>
        <Seo
          title="Flood Risk Policy Conflict"
          description="Information on why we believe the Guildford & Godalming Greenway to be in conflict with Flood Risk Policy."
        />
        <ResetCSS />
        <GlobalStyle />
        {/* Start charity wrapper section */}
        <CharityWrapper>
          <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
            <Navbar />
          </Sticky>
          <DrawerProvider>
            <DrawerSection />
          </DrawerProvider>
          <ContentWrapper>
            <BannerSection />
            <BodySection />
            <CTAOne />
            <Footer />
          </ContentWrapper>
        </CharityWrapper>
        {/* End of charity wrapper section */}
      </Fragment>
    </ThemeProvider>
  );
};
export default Index;
